<template>
    <b-card title="Podvojene vloge">
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">
            <span v-if="props.column.display_type === 1">
                <router-link :to="{name: 'submissions', query: {'emso': props.formattedRow.emso}}">
                    {{ props.formattedRow[props.column.field] }}
                </router-link>
            </span>
            <span v-else-if="props.column.display_type === 2" class="d-flex justify-content-center">
                <router-link :to="{name: 'submissions', query: {'emso': props.formattedRow.emso}}">
                    <b-button variant="info">Ogled vlog</b-button>
                </router-link>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>
    </b-card>
</template>

<script>
    import {BCard, BButton} from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'

    export default {
        components: {
            Table,
            BCard,
            BButton
        },
        data() {
            return {
                columns: [
                    {
                        field: (value) => { return [value.first_name, value.last_name] },
                        label: 'Ime in priimek',
                        tdClass: 'align-middle font-weight-bold',
                        formatFn: (values) => {
                            return `${values[0]} ${values[1]}`
                        },
                        filterOptions: {
                            enabled: true
                        },
                        display_type: 1
                    },
                    {
                        field: 'emso',
                        label: 'Emšo',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'duplicate_count',
                        label: 'Število prijav',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },

                    {
                        field: 'actions',
                        label: 'Dejanja',
                        tdClass: 'align-middle',
                        display_type: 2
                    }
                ],
                items: []
            }
        },
        methods: {
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/submission/duplicates')
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
.text-primary {

}
</style>
